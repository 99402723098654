/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { DocumentContext } from "~context/DocumentContext";
import CursorHoverable from "~components/CursorHoverable";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import { fancyError, fancyLog, validateEmail } from "~utils/helpers";

const ContactPage = ({ data, location }) => {
  const { device } = useContext(DocumentContext);

  const [email, setEmail] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);

  const { frontmatter } = data.markdownRemark;

  //

  const onSubmit = e => {
    e.preventDefault();

    if (
      typeof window !== `undefined` &&
      window.location.href.includes(`localhost:8000`)
    ) {
      setSubmitting(true);

      setTimeout(() => {
        setSubmitting(false);
        setSubmitted(true);
      }, 3000);

      return false;
    }

    if (
      !valid ||
      submitting ||
      submitted ||
      !process.env.GATSBY_NETLIFY_FUNCTIONS ||
      !process.env.GATSBY_MAILCHIMP_API_KEY ||
      !process.env.GATSBY_MAILCHIMP_LIST_ID
    ) {
      return false;
    }

    setSubmitting(true);

    const mailchimpData = {
      email,
      list_id: process.env.GATSBY_MAILCHIMP_LIST_ID
    };

    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS}/mailchimp`, {
      body: JSON.stringify(mailchimpData),
      method: `POST`
    })
      .then(() => {
        fancyLog(`Mailchimp Complete`);
        setSubmitting(false);
        setSubmitted(true);
      })
      .catch(error => {
        fancyError(error);
      });

    return false;
  };

  useEffect(() => {
    setValid(validateEmail(email));
  }, [email]);

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="contact-page w-full relative pt-v12 pb-v8 bg-black text-white">
        <section className="animation-appear w-full relative block">
          <div className="grid">
            {frontmatter?.heading && (
              <div className="grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1 relative">
                <h2
                  className={`mt-v1 xs:mt-v8 mb-v4 xs:mb-v1 ${
                    device && device === `desktop` ? `f3` : `f5`
                  }`}
                >
                  {frontmatter.heading}
                </h2>
              </div>
            )}

            {frontmatter.sections.map(section => (
              <article className="grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1 relative mt-v6 xs:mt-v24">
                {section?.heading && (
                  <h3
                    className={`${
                      device && device === `desktop` ? `f5` : `b1`
                    }`}
                  >
                    {section.heading}
                  </h3>
                )}

                <p
                  className={`mt-v1 xs:mt-v8 ${
                    device && device === `desktop` ? `f3` : `f5`
                  }`}
                >
                  {section.content}
                </p>

                {section.type === `form` && (
                  <form
                    className={`contact-page__form w-1/2 xs:w-full relative block mt-v1 ${
                      submitting || submitted
                        ? `opacity-50 pointer-events-none`
                        : ``
                    }`}
                    onSubmit={onSubmit}
                  >
                    <CursorHoverable>
                      <input
                        className={`w-full relative block mt-v4 xs:mt-v8 pb-v1 ${
                          device && device === `desktop` ? `f3` : `f5`
                        } border-b-white`}
                        onChange={e => setEmail(e.currentTarget.value)}
                        placeholder="Enter your email"
                        type="email"
                      />
                    </CursorHoverable>

                    <div className="w-full relative flex justify-end">
                      <CursorHoverable>
                        <input
                          className={`mt-v1 xs:mt-v3 ${
                            device && device === `desktop` ? `f4` : `f5`
                          }`}
                          value={submitted ? `Thanks!` : `Submit`}
                          type="submit"
                        />
                      </CursorHoverable>
                    </div>
                  </form>
                )}

                {section.type === `default` && (
                  <CursorHoverable>
                    <a
                      href={`mailto:${section.email}`}
                      className={`block mt-v1 xs:mt-v4 underline ${
                        device && device === `desktop` ? `f3` : `f5`
                      }`}
                    >
                      {section.email}
                    </a>
                  </CursorHoverable>
                )}
              </article>
            ))}

            <div className="grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1 mt-v4">
              <CursorHoverable>
                <a
                  href="https://www.instagram.com/with_you.exhibition/"
                  className={`${
                    device && device === `desktop` ? `f5` : `b1`
                  } hover-underline`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  + Follow us on Instagram
                </a>
              </CursorHoverable>
            </div>
          </div>
        </section>
      </Layout>

      <Footer />
    </>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading

        sections {
          type
          heading
          content
          email
        }

        seoDescription
        seoKeywords
      }
    }
  }
`;
